// React&NextJS
import React, { useState, useEffect } from 'react'
// 3rd party libraries
import algoliasearch from 'algoliasearch'
import {
    Configure,
    InstantSearch,
    connectHits,
    connectPagination,
} from 'react-instantsearch-dom'
// Services&Helper functions
import helperFunctions from '../../../utils/helperFunctions'
// Types
import { MemoryRefType, ScrapedFunerals } from '../../../types/types'
// Styles
import s from './RecentlyDeceacedModule.module.scss'
// Components
import { Button } from '../../core/button/Button'
import Card from '../../cards/card/Card'
import { Container } from '../../core/container/Container'
import { Grid } from '../../core/grid/Grid'
import { Text } from '../../core/text/Text'
import NewShareModal from '../modals/NewShareModal'

const client = algoliasearch(
    process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
    process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
)

type HitType = MemoryRefType & {
    objectID: string
}

type HitsType = { hits: Array<HitType> }

function Hits({ hits }: HitsType) {
    const memories = hits.map((h: any) => {
        return { ...h.data, objectID: h.objectID }
    })

    const [isOpen, setIsOpen] = useState(false)
    const [funeral, setFuneral] = useState<ScrapedFunerals>(null)

    const closeModal = () => {
        setFuneral(null)
        setIsOpen(false)
    }
    return (
        <>
            <NewShareModal
                isOpen={isOpen}
                closeModal={() => closeModal()}
                title={
                    'Viltu stofna eða benda aðstandanda á að stofna minningarsíðu?'
                }
                info={`Við sjáum að ekki er til minningarsíða um ${funeral?.fullName} á Minningar.is. Þú getur stofnað minningarsíðu í nokkrum einföldum skrefum, eða bent aðstandanda á það.`}
                data={funeral}
                imgURL="https://firebasestorage.googleapis.com/v0/b/minning-f44b2.appspot.com/o/shareCards%2Fdefault-card.png?alt=media&token=ed39ad70-200f-48fe-a8f1-277b37bb86f5"
            >
                <Button
                    type="button"
                    variant="ghost"
                    className={s.btnPadd}
                    to={`/memories/form?deceasedId=${funeral?.objectID}`}
                >
                    Stofna minningarsíðu
                </Button>
                <Text variant="date">Senda hugmyndina á aðstandanda</Text>
            </NewShareModal>
            <Grid className={s.grid} key={hits && hits[0]?.data?.fullName}>
                {memories.map((m, i) => {
                    const birthDate: string = helperFunctions.getDate(m.birth)
                    const deathDate: string = helperFunctions.getDate(m.death)

                    if (!m.scraped) {
                        return (
                            <Card
                                key={i}
                                size="4"
                                header={m.fullName}
                                headerSize="h4"
                                lifeTime={`${birthDate} - ${deathDate}`}
                                imageSrc={m.profileImg}
                                to={{
                                    link: `/memories/${m.objectID}`,
                                    label: 'Lesa Meira',
                                }}
                            />
                        )
                    } else {
                        return (
                            <Card
                                key={i}
                                size="4"
                                header={m.fullName}
                                headerSize="h4"
                                lifeTime={`${birthDate} - ${deathDate}`}
                                imageSrc={m.profileImg}
                                // scrapedInfo={m.scrapeInfo}
                                type="recent"
                                to={{
                                    id: `${m.fullName}-recent`,
                                    link: '',
                                    onClick: () => {
                                        setFuneral(m)
                                        setIsOpen(true)
                                    },
                                    label: 'Stofna minningarsíðu',
                                }}
                            />
                        )
                    }
                })}
            </Grid>
        </>
    )
}

export const RecentlyDeceacedModule = () => {
    const [device, setDevice] = useState(4)
    const [firstRender, setFirstRender] = useState(true)
    const CustomHits = connectHits(Hits)

    const Pagination = ({ currentRefinement, nbPages, refine }) => {
        return (
            <Container className={s.header}>
                <Text variant="h5">Nýleg andlát</Text>
                {device !== 1 && (
                    <div className={s.buttons}>
                        <Button
                            variant="arrow"
                            arrowDirection="l"
                            disabled={currentRefinement === 1}
                            onClick={(event) => {
                                event.preventDefault()
                                refine(currentRefinement - 1)
                            }}
                        />
                        <Button
                            variant="arrow"
                            disabled={currentRefinement === nbPages}
                            onClick={(event: React.SyntheticEvent) => {
                                event.preventDefault()
                                refine(currentRefinement + 1)
                            }}
                        />
                    </div>
                )}
            </Container>
        )
    }

    const CustomPagination = connectPagination(Pagination)

    useEffect(() => {
        function changeDevice() {
            if (window.innerWidth <= 479) {
                setDevice(1)
            } else if (window.innerWidth <= 719) {
                setDevice(2)
            } else {
                setDevice(4)
            }
        }
        window.addEventListener('resize', changeDevice)
        if (firstRender) {
            changeDevice()
            setFirstRender(false)
        }
        return () => window.removeEventListener('resize', changeDevice)
    }, [])
    // var today = new Date()

    // var lastweek = new Date(
    //     today.getFullYear(),
    //     today.getMonth(),
    //     today.getDate() - 28
    // ).getTime()

    return (
        <InstantSearch searchClient={client} indexName="memories">
            <CustomPagination />

            <Configure
                hitsPerPage={device}
                // filters={`data.death >= ${lastweek}`}
            />
            <CustomHits />
            <Container>
                <Button
                    to="/recently-deceased"
                    variant="arrow"
                    className={s.viewMore}
                >
                    Skoða öll nýleg andlát
                </Button>
            </Container>
        </InstantSearch>
    )
}
